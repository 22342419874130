import React, { useState, useEffect } from 'react';
import { fetchAdminCharts, addChart, deleteChart, deleteAllCharts } from '../../services/api';
import Footer from './Footer';

const AdminPanel = () => {
  const [charts, setCharts] = useState([]);
  const [newChartName, setNewChartName] = useState('');
  const [artist, setArtist] = useState('');
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const chartsPerPage = 10;

  useEffect(() => {
    fetchAdminCharts()
      .then(response => {
        setCharts(response.data);
      })
      .catch(error => {
        console.error('Error fetching charts:', error);
        setError('Failed to fetch charts');
      });
  }, []);

  const handleAddChart = () => {
    if (!newChartName || !artist) {
      setError('All fields are required.');
      return;
    }

    const chartData = { name: newChartName, artist };

    addChart(chartData)
      .then(response => {
        setCharts([...charts, response.data]);
        setNewChartName('');
        setArtist('');
        setError('');
      })
      .catch(error => {
        console.error('Error adding chart:', error.response ? error.response.data : error.message);
        setError('Failed to add chart');
      });
  };

  const handleDeleteChart = (id) => {
    deleteChart(id)
      .then(() => {
        setCharts(charts.filter(chart => chart.id !== id));
      })
      .catch(error => {
        console.error('Error deleting chart:', error);
        setError('Failed to delete chart');
      });
  };

  const handleDeleteAllCharts = () => {
    deleteAllCharts()
      .then(() => {
        setCharts([]);
      })
      .catch(error => {
        console.error('Error deleting all charts:', error);
        setError('Failed to delete all charts');
      });
  };

  const sortedCharts = [...charts].sort((a, b) => b.votes - a.votes);

  const indexOfLastChart = currentPage * chartsPerPage;
  const indexOfFirstChart = indexOfLastChart - chartsPerPage;
  const currentCharts = sortedCharts.slice(indexOfFirstChart, indexOfLastChart);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedCharts.length / chartsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <main className="p-6 max-w-4xl mx-auto bg-[#575757] text-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">Admin Panel</h2>
        {error && <p className="text-red-400 mb-4">{error}</p>}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Charts Hinzufügen</h3>
          <div className="flex flex-col mb-4">
            <label htmlFor="artist" className="text-gray-300 mb-2">Interpret</label>
            <input
              type="text"
              id="artist"
              value={artist}
              onChange={(e) => setArtist(e.target.value)}
              className="border p-2 rounded mb-2 bg-gray-800 text-white"
            />
            <label htmlFor="chartName" className="text-gray-300 mb-2">Title</label>
            <input
              type="text"
              id="chartName"
              value={newChartName}
              onChange={(e) => setNewChartName(e.target.value)}
              className="border p-2 rounded mb-2 bg-gray-800 text-white"
            />
            <button
              onClick={handleAddChart}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Add Chart
            </button>
          </div>
        </div>
        <div className="mb-6">
          <button
            onClick={handleDeleteAllCharts}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Delete All Charts
          </button>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-2">Existing Charts</h3>
          <ul className="space-y-4">
            {currentCharts.map((chart, index) => (
              <li key={chart.id} className="flex items-center p-4 border-b border-gray-700">
                <div className="flex items-center space-x-4">
                  <span className="font-semibold text-xl">{indexOfFirstChart + index + 1}.</span>
                  {chart.cover_url && (
                    <img
                      src={chart.cover_url}
                      alt={chart.name}
                      className="w-16 h-16 object-cover"
                    />
                  )}
                </div>
                <div className="flex-1 ml-4">
                  <span className="font-semibold">{chart.artist}</span>
                  <span className="text-gray-400 block">{chart.name}</span>
                  <span className="text-gray-400 block">Votes: {chart.votes}</span>
                  <span className="text-gray-400 block">Hinzugefügt am: {new Date(chart.created_at).toLocaleDateString()}</span>
                </div>
                <div className="flex items-center space-x-4 ml-4">
                  <button
                    onClick={() => handleDeleteChart(chart.id)}
                    className="px-4 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center mt-4">
          <ul className="flex space-x-2">
            {pageNumbers.map(number => (
              <li key={number}>
                <button
                  onClick={() => paginate(number)}
                  className={`px-4 py-2 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white border-gray-600'}`}
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminPanel;
