import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://backend.djbeatfire.de:15000/api/login', { username, password })
      .then(response => {
        const token = response.data.token;
        localStorage.setItem('token', token);

        // Set a timer to remove the token after 24 hours
        setTimeout(() => {
          localStorage.removeItem('token');
          toast.info('Session expired. Please login again.');
        }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

        onLogin();
        toast.success('Login successful');
      })
      .catch(error => {
        setError('Invalid credentials');
        toast.error('Login failed');
      });
  };

  return (
    <div className="p-6 max-w-md mx-auto bg-[#575757] text-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Login</h2>
      {error && <p className="text-red-400 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2" htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border p-2 w-full bg-gray-800 text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2" htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border p-2 w-full bg-gray-800 text-white"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
