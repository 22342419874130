import axios from 'axios';

// Basis-URL für Axios-Instanz
const api = axios.create({
  baseURL: 'https://backend.djbeatfire.de:15000/api',
});

// Funktion zum Hinzufügen des Tokens in den Header
const addTokenToHeaders = () => {
  const token = localStorage.getItem('token');
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

// Spotify-Zugangsdaten
const SPOTIFY_CLIENT_ID = 'dbcd378a48a5490697c19a7a07673236'; // Ersetzen
const SPOTIFY_CLIENT_SECRET = '5a0fa356ee2f4507b6eafbcc52d7de5b'; // Ersetzen

// Spotify Token Endpoint
const SPOTIFY_TOKEN_URL = 'https://accounts.spotify.com/api/token';

// Hilfsfunktion zum Erhalten des Spotify Access Tokens
const getSpotifyAccessToken = async () => {
  try {
    const credentials = `${SPOTIFY_CLIENT_ID}:${SPOTIFY_CLIENT_SECRET}`;
    const encodedCredentials = btoa(credentials); // Basis-64-Kodierung im Browser

    const response = await axios.post(SPOTIFY_TOKEN_URL, new URLSearchParams({
      grant_type: 'client_credentials'
    }), {
      headers: {
        'Authorization': `Basic ${encodedCredentials}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data.access_token;
  } catch (error) {
    console.error('Error fetching Spotify access token:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Beispiel für das Abrufen der Charts
export const fetchAdminCharts = async () => {
  addTokenToHeaders(); // Token hinzufügen
  try {
    const response = await api.get('/admin/charts');
    return response;
  } catch (error) {
    console.error('Error fetching admin charts:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Funktion zum Generieren der YouTube-Such-URL
const generateYouTubeSearchUrl = (query) => {
  const formattedQuery = encodeURIComponent(query); // Formatierung für die URL
  return `https://www.youtube.com/results?search_query=${formattedQuery}`;
};

// Beispiel für das Hinzufügen der Charts
export const addChart = async (chartData) => {
  addTokenToHeaders(); // Token hinzufügen

  try {
    const accessToken = await getSpotifyAccessToken();
    
    const response = await axios.get('https://api.spotify.com/v1/search', {
      params: {
        q: `${chartData.name} ${chartData.artist}`,
        type: 'track', // Suche nach Tracks
        limit: 1
      },
      headers: {
        Authorization: `Bearer ${accessToken}` // Token im Header hinzufügen
      }
    });

    if (response.data.tracks.items.length > 0) {
      const track = response.data.tracks.items[0];
      const coverUrl = track.album?.images[0]?.url || '';
      const spotifyUrl = track.external_urls?.spotify || ''; // Spotify-URL extrahieren
      chartData.cover_url = coverUrl;
      chartData.spotify_url = spotifyUrl; // Spotify-URL hinzufügen
    } else {
      console.warn('No tracks found for the given query.');
      chartData.cover_url = '';
      chartData.spotify_url = ''; // Leere Spotify-URL hinzufügen
    }

    // YouTube-Such-URL erstellen
    chartData.youtube_url = generateYouTubeSearchUrl(`${chartData.name} ${chartData.artist}`);

  } catch (error) {
    console.error('Error fetching data:', error.response ? error.response.data : error.message);
  }

  try {
    const response = await api.post('/admin/charts', chartData);
    return response;
  } catch (error) {
    console.error('Error adding chart:', error.response ? error.response.data : error.message);
    throw error;
  }
};




export const deleteChart = async (id) => {
  addTokenToHeaders(); // Token hinzufügen
  try {
    const response = await api.delete(`/admin/charts/${id}`);
    return response;
  } catch (error) {
    console.error('Error deleting chart:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchCharts = async () => {
  try {
    const response = await axios.get('https://backend.djbeatfire.de:15000/api/charts');
    return response;
  } catch (error) {
    console.error('Error fetching charts:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Funktion zum Abstimmen für einen einzelnen Song
export const voteForChart = async (songId) => {
  try {
    const response = await axios.post('https://backend.djbeatfire.de:15000/api/charts/vote', { songId });
    return response;
  } catch (error) {
    console.error('Error voting for chart:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteAllCharts = async () => {
  addTokenToHeaders(); // Token hinzufügen
  try {
    const response = await api.delete('/admin/charts');
    return response;
  } catch (error) {
    console.error('Error deleting all charts:', error.response ? error.response.data : error.message);
    throw error;
  }
};
