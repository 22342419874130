import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Importiere die Tailwind CSS Datei
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'; // Importiere BrowserRouter

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// Wenn du die Leistung deiner App messen möchtest, übergib eine Funktion
// um Ergebnisse zu protokollieren (zum Beispiel: reportWebVitals(console.log))
// oder sende an einen Analyse-Endpunkt. Mehr dazu: https://bit.ly/CRA-vitals
reportWebVitals();
