import React from 'react';

const InfoSection = () => {
  return (
    <section className="bg-gray-100 p-6 rounded-lg shadow-md mb-8 font-hind-siliguri">
      <h2 className="text-2xl font-semibold mb-4">Hallo liebe Besucher,</h2>
      <p className="text-gray-700 mb-4">
        Willkommen auf unserer Votingseite. Hier könnt ihr euren Lieblingstitel abstimmen.
      </p>
      <p className="text-gray-700 mb-4">
        Wie das Ganze funktioniert bzw. welche Möglichkeiten ihr habt, erfahrt ihr jetzt.
      </p>
      <p className="text-gray-700 mb-4">
        Die Promotionsendung läuft alle 4 Wochen, und zwar den letzten Samstag im Monat von 15-18 Uhr mit unserem Holger R., abgestimmt werden kann bis Freitag 18 Uhr, einen Tag vor der Promotionsendung. Jeder hat die Chance, einmal am Tag für fünf verschiedene Titel zu voten.
      </p>
      <p className="text-gray-700 mb-4">
        Von jedem Interpreten nehmen wir nur einen Titel in die Votingcharts auf. Alle Titel bleiben bis zu 8 Wochen in den Votingcharts, es sei denn, ein Interpret hat einen neuen Titel. Dann kann er nach der Promotionsendung entscheiden, ob der alte oder der neue Titel in die Votingcharts mit hineinkommt.
      </p>
      <p className="text-gray-700 mb-4">
        In jeden Titel könnt ihr hineinhören oder euch das Video ansehen, indem ihr neben dem angezeigten Titel auf das Feld Vorhören oder YouTube klickt.
      </p>
      <p className="text-gray-700 font-semibold mb-4">
        Nun zu der wichtigsten Information:
      </p>
      <p className="text-red-600 mb-4">
        Sollten wir feststellen, dass versucht wird, Votes durch VPNs/Proxys zu beeinflussen, werden wir die Stimmen dieser VPNs/Proxys aus der Umfrage nicht mitzählen.
      </p>
      <p className="text-gray-700 mb-4">
        Wir wünschen ab jetzt viel Spaß beim Voten eurer Lieblingstitel!
      </p>
      <p className="text-gray-700 font-semibold">
        Voten vom 29.06.24 bis zum 26.07.2024 bis 17:59 Uhr
      </p>
    </section>
  );
};

export default InfoSection;
