import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ isLoggedIn, onLogout }) => {
  return (
    <header className="bg-gray-800 text-white py-4 font-hind-siliguri">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">Radio-Hörbar Hörercharts</h1>
        <nav>
          <ul className="flex space-x-4 items-center">
          <li>
              <Link to="/" className="hover:text-gray-400">Home</Link>
            </li>
            <li>
              <Link to="/charts" className="hover:text-gray-400">Charts</Link>
            </li>
            {isLoggedIn ? (
              <>
            <li>
              <Link to="/admin" className="hover:text-gray-400">Admin Panel</Link>
            </li>
                <li>
                  <button
                    onClick={onLogout}
                    className="bg-gray-600 px-4 py-2 rounded hover:bg-gray-500"
                  >
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>

                <li>
                  <Link to="/login" className="hover:text-gray-400">Admin Login</Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
